"use client";
import { createContext, ReactNode, useContext, useEffect, useRef } from "react";
import { Howl, Howler } from "howler";
import bgMusic from "@/assets/game_client_sound/audio-sprite/bgMusic.mp3";
import { useCookieContext } from "./CookieProvider";
import Sound from "@/components/common/sound";
import bgSound from "@/assets/game_client_sound/10.게임배경음악_cut_sfs.mp3";

export enum AudioSpriteID {
  CLICK = "CLICK",
  BET_CONFIRM = "BET_CONFIRM",
  CHIP_SELECT = "CHIP_SELECT",
  CHIP_BET = "CHIP_BET",
  START_VOICE = "START_VOICE",
  STOP_VOICE = "STOP_VOICE",
  P_WIN = "P_WIN",
  B_WIN = "B_WIN",
  T_WIN = "T_WIN",
  COUNT_DOWN = "COUNT_DOWN",
  ERROR = "ERROR",
}

type SoundContext = {
  playBgMusic: () => void;
  stopBgMusic: () => void;
  isBgMusicPlaying: () => boolean;
  playSprite: (id: AudioSpriteID) => void;
  setBgMusicVolume: (volume: number) => void;
  bgMusic: Howl | null;
  playBGMusicDesktop: () => void;
  stopBgMusicDesktop: () => void;
};

const SoundContext = createContext<SoundContext | undefined>(undefined);

export function useSoundContext() {
  const context = useContext(SoundContext);
  if (context === undefined) {
    throw new Error("useSoundContext must be used within a SoundProvider");
  }
  return context;
}

export function SoundProvider({ children }: { children: ReactNode }) {
  const { backgroundVolume, sceneVolume } = useCookieContext();
  const audioSpriteRef = useRef<Howl | null>(null);
  const bgMusicRef = useRef<Howl | null>(null);
  useEffect(() => {
    console.log("Sounds Context Loaded");

    const baseUrl = window.location.origin;

    const bgMusicSources = [baseUrl + "/assets/sound-fx/dw-bg-music.mp3"];

    const audioSpriteSources = [
      baseUrl + "/assets/sound-fx/audio-sprite.ac3",
      baseUrl + "/assets/sound-fx/audio-sprite.m4a",
      baseUrl + "/assets/sound-fx/audio-sprite.mp3",
      baseUrl + "/assets/sound-fx/audio-sprite.ogg",
    ];

    bgMusicRef.current = new Howl({
      src: bgMusicSources,
      loop: true,
    });

    audioSpriteRef.current = new Howl({
      src: audioSpriteSources,
      preload: true,
      sprite: {
        CLICK: [0, 340.1360544217687],
        BET_CONFIRM: [2000, 469.90929705215433],
        CHIP_SELECT: [4000, 218.82086167800452],
        CHIP_BET: [6000, 223.10657596371897],
        START_VOICE: [8000, 940.4081632653067],
        STOP_VOICE: [10000, 835.9183673469381],
        P_WIN: [12000, 757.5510204081635],
        B_WIN: [14000, 783.6734693877556],
        T_WIN: [16000, 705.3061224489809],
        COUNT_DOWN: [18000, 1004.9659863945592],
        ERROR: [21000, 1000],
      },
    });

    return () => {
      if (bgMusicRef.current) {
        bgMusicRef.current.stop();
        bgMusicRef.current.unload();
        bgMusicRef.current = null;
      }
    };
  }, []);

  const playSprite = (id: AudioSpriteID) => {
    if (!audioSpriteRef.current) {
      return;
    }
    audioSpriteRef.current.play(id);
  };

  const playBgMusic = () => {
    if (!bgMusicRef.current) {
      return;
    }
    bgMusicRef.current.play();
  };

  const stopBgMusic = () => {
    if (!bgMusicRef.current) {
      return;
    }
    bgMusicRef.current.stop();
  };

  const isBgMusicPlaying = (): boolean => {
    if (!bgMusicRef.current) {
      return false;
    }
    return bgMusicRef.current.playing();
  };

  const setBgMusicVolume = (volume: number) => {
    if (!bgMusicRef.current) {
      return;
    }
    bgMusicRef.current.volume(volume);
  };

  const setSceneVolume = (volume: number) => {
    if (!audioSpriteRef.current) {
      return;
    }
    audioSpriteRef.current.volume(volume);
  };

  const bgRef = useRef<HTMLAudioElement>(null);

  const playBGMusicDesktop = () => {
    if (bgRef.current) {
      try {
        bgRef.current.play();
      } catch (error) {
        console.error("Error playing media:", error);
      }
    }
  };

  const stopBgMusicDesktop = () => {
    if (bgRef.current) {
      bgRef.current.pause();
    }
  };

  useEffect(() => {
    console.log("SoundCtx: BgMusicVolume ", backgroundVolume);
    setBgMusicVolume(backgroundVolume);
  }, [backgroundVolume]);

  useEffect(() => {
    console.log("SoundCtx: SceneVolume ", sceneVolume);
    setSceneVolume(sceneVolume);
  }, [sceneVolume]);

  return (
    <SoundContext.Provider
      value={{
        playBgMusic,
        stopBgMusic,
        isBgMusicPlaying,
        playSprite,
        setBgMusicVolume,
        playBGMusicDesktop,
        stopBgMusicDesktop,
        bgMusic: bgMusicRef.current,
      }}
    >
      {children}
      <Sound loop sref={bgRef} src={bgSound} />
    </SoundContext.Provider>
  );
}
