"use client";
import React from "react";
import Image from "next/image";
import maintanceLogo from "@/assets/logged-out/logout.png";
import ellipseBottom from "@/assets/maintenance/ellipse-bottom.png";
import ellipseTop from "@/assets/maintenance/ellipse-top.png";
import dowinnlogo from "@/assets/maintenance/dowinn-logo.png";
import { t } from "i18next";
import { useCookieContext } from "@/common/context/CookieProvider";

type SupportedLanguages = "kr" | "cn" | "jp" | "en";

const LoggedOut = () => {
  const { language } = useCookieContext() as { language: SupportedLanguages };

  const loggedOutMessage = [
    {
      kr: "로그아웃 되셨습니다",
      cn: "您已登出",
      jp: "ログアウトされました",
      en: "You have been logged out",
    },
    {
      kr: "죄송합니다. 이 페이지는 더 이상 이용할 수 없거나 접근 권한이 없습니다. 저희 서비스를 이용해 주셔서 감사합니다! 추가 도움이 필요하시면 고객 지원팀에 문의해 주십시오.",
      cn: "抱歉，此页面已不可用或您已无法访问。感谢您使用我们的服务！如需进一步帮助，请联系客户支持团队。",
      jp: "申し訳ございません。このページは現在ご利用いただけないか、アクセス権限がありません。弊社サービスをご利用いただきありがとうございます！ご不明点がございましたら、サポートチームまでお問い合わせください。",
      en: "We're sorry, but this page is no longer available or you no longer have access to it. Thank you for using our service! If you need further assistance, please contact our support team.",
    },
  ];

  const titleToDisplay =
    loggedOutMessage[0][language] || loggedOutMessage[0].en;
  const textToDisplay = loggedOutMessage[1][language] || loggedOutMessage[1].en;

  return (
    <div className="flex h-screen items-center justify-between px-24 min-[300px]:max-[900px]:justify-center min-[300px]:max-[900px]:text-center min-[300px]:max-[600px]:mt-[-10%]">
      <div className="absolute left-0 min-[300px]:max-[900px]:hidden">
        <Image src={ellipseTop} alt="" className="h-full w-[30cqw]" />
      </div>
      <div className="z-20 mt-[-5%] flex w-[60%] flex-col min-[300px]:max-[900px]:w-[100%] min-[300px]:max-[900px]:items-center min-[300px]:max-[900px]:justify-center">
        <Image
          src={maintanceLogo}
          alt=""
          className="w-[120px] min-[300px]:max-[900px]:w-[80px]"
        />
        <h1 className="my-8 text-nowrap text-[54px] font-bold text-white min-[300px]:max-[900px]:my-2 min-[300px]:max-[900px]:text-[24px]">
          {titleToDisplay}
        </h1>
        <p className="w-[55%] text-[18px] text-[#A1A1AA] min-[300px]:max-[900px]:w-[80%] min-[300px]:max-[900px]:text-[14px]">
          {textToDisplay}
        </p>
      </div>

      <div className="flex w-[40%] justify-center opacity-80 min-[300px]:max-[900px]:absolute min-[300px]:max-[600px]:w-full">
        {/* <Image
          src={dowinnlogo}
          alt=""
          className="z-20 h-full w-[30cqw] min-[300px]:max-[900px]:w-full"
        /> */}
        <div className="absolute bottom-0 right-0 min-[300px]:max-[900px]:hidden">
          <Image src={ellipseBottom} alt="" className="h-full w-[60cqw]" />
        </div>
      </div>
    </div>
  );
};

export default LoggedOut;
