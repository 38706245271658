"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { AxiosError } from "axios";
import clsx from "clsx";
import { useForm, SubmitHandler, useFormState } from "react-hook-form";
import {
  ILoginReq,
  LoginResponse,
  LOGIN_MUTATION,
} from "@/common/api/mutation/login_mutation";
import DWVIPlogo from "@/assets/dw-vip-logo.png";
import {
  SESSIONSTORAGE_ACTIVE_CATEGORY,
  SESSIONSTORAGE_ACTIVE_LAYOUT,
  SESSIONSTORAGE_DEFAULT_CHIPS,
  SESSIONSTORAGE_USER_DATA,
  SESSIONSTORAGE_USER_ID,
} from "@/common/utils/constants";
import { Loader } from "./loader";
import {
  defaultChips,
  userDataVar,
  accessToken as accessTokenVar,
  baseChipsVar,
  activeCatVar,
  isTypeHeritageVar,
  isApiGameUrlActiveVar,
} from "@/common/utils/apollo";
import { useRouter } from "next/navigation";
import useWindowSize from "@/common/hooks/useWindowSize";
import { LoginMultiLang } from "../common/login_multilang";
import { IUserData } from "@/common/types";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useCookieContext } from "@/common/context/CookieProvider";
import {
  CookieKeys,
  getLanguage,
  getTheme,
  getUserCookies,
} from "@/common/utils/cookiesManager";
import { CHIP_VALUES } from "@/common/lib/list_chips";
import { useDomainCheck } from "@/common/context/DomainContext";
import { useUserAgent } from "@/common/hooks/useUserAgent";

export type GenerateKeyPayload = {
  code: number;
  message: string;
  balance: number;
  currency: string;
  rate: string;
};

const SubmitMessage = () => {
  const router = useRouter();
  // const manager = useSocketManager();
  // const [socket, setSocket] = useState<Socket | null>(null);

  // useEffect(() => {
  //   if (!socket) {
  //     setSocket(manager.socket("/"));
  //   } else {
  //     socket.connect();
  //   }
  // }, [socket, manager]);

  // Hooks
  const isTargetDomain = useDomainCheck();
  const { register, handleSubmit, control } = useForm<ILoginReq>({
    mode: "onChange",
  });
  const { isSubmitting } = useFormState({ control });
  const { width, height } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { userAgent } = useUserAgent();

  // Global States
  const userData = useReactiveVar(userDataVar);
  const isActiveApiGameUrl = useReactiveVar(isApiGameUrlActiveVar);

  // Context API
  const {
    theme,
    language,
    setTheme,
    setLanguage,
    setSound,
    userCookies,
    setSceneVolume,
    setBackgroundVolume,
    setUserCookies,
  } = useCookieContext();

  // Local States
  const [isSuccess, setIsSuccess] = useState(false);
  const [isI18nReady, setIsI18nReady] = useState(false);
  const [isLangTriggered, setIsLangTriggered] = useState(false);

  const isMobile = userAgent?.device.type === "mobile";

  useEffect(() => {
    if (i18n.isInitialized) {
      setIsI18nReady(true);
    } else {
      i18n.on("initialized", () => setIsI18nReady(true));
    }

    // const savedTheme = getTheme();
    // const savedLanguage = getLanguage();

    // console.log(userCookies);

    // Detect browser language

    // if (CookieKeys.Theme !== undefined) {
    //   console.log(theme);
    //   setLanguage()
    // }

    // if (CookieKeys.Language !== undefined) {
    //   setLanguage(language);
    //   i18n.changeLanguage(language);
    // } else {
    //   const browserLang = navigator.language.toLowerCase();
    //   let newLang;

    //   if (browserLang.startsWith("ko")) {
    //     newLang = "kr";
    //   } else if (browserLang.startsWith("zh")) {
    //     newLang = "cn";
    //   } else if (browserLang.startsWith("ja")) {
    //     newLang = "jp";
    //   } else {
    //     newLang = "en";
    //   }

    //   setLanguage(newLang);
    //   i18n.changeLanguage(newLang);
    // }

    // // Detect browser theme (dark/light mode)
    // if (CookieKeys.Theme !== undefined) {
    //   setTheme(theme);
    // } else {
    //   const prefersDarkMode = window.matchMedia(
    //     "(prefers-color-scheme: dark)",
    //   ).matches;
    //   setTheme(prefersDarkMode);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const onSubmit: SubmitHandler<ILoginReq> = async (data) => {
    const toFilData = {
      ...data,
      user_id: data.user_id.toLocaleLowerCase().replace(/\s+/g, ""),
      language: "EN",
    };

    if (data.password.length === 5) {
      return alert("invalid credential details error");
    } else if (data.password.length < 4) {
      return null;
    }

    const loginResponse = await LOGIN_MUTATION(toFilData);
    if (loginResponse instanceof AxiosError || loginResponse === undefined) {
      if (loginResponse !== undefined) {
        console.error(loginResponse);
        if (loginResponse.status === 400) {
          if (loginResponse.response?.data.code === 40050) {
            alert("System Is Under Maintenance");
          } else if (loginResponse.response?.data.code === 40161) {
            alert(t("login_failed_please_contact_the_administrator"));
          } else {
            alert("Invalid Id or Password");
          }
        } else if (loginResponse.status === 409) {
          alert("Member Already Logged In");
        } else {
          alert("login request failed");
        }
      }
    } else {
      if (loginResponse.status === 201) {
        console.log("Login Success");
        const data: LoginResponse = loginResponse.data;
        const accessToken: string = loginResponse.headers["x-authorization"];
        accessTokenVar(accessToken.replace("Bearer ", ""));
        sessionStorage.setItem(
          "access_token",
          accessToken.replace("Bearer ", ""),
        );
        if (data.code === 0) {
          const newUserData: IUserData = {
            ...userData,
            ...data.game_data,
            auto_confirm: false,
          };
          // global state update
          userDataVar(newUserData);
          sessionStorage.setItem(
            SESSIONSTORAGE_USER_DATA,
            JSON.stringify(newUserData),
          );

          const prefersDarkMode = window.matchMedia(
            "(prefers-color-scheme: dark)",
          ).matches;

          console.log("prefersDarkMode", prefersDarkMode);

          const existingCookieData = getUserCookies(data.game_data.user_id);

          if (existingCookieData !== undefined) {
            const filLang = isLangTriggered
              ? language
              : existingCookieData.language;

            // const filTheme =
            //   existingCookieData.theme !== prefersDarkMode
            //     ? prefersDarkMode
            //     : existingCookieData.theme;

            setTheme(existingCookieData.theme);
            setLanguage(filLang);
            setSound(existingCookieData.sound);
            setSceneVolume(existingCookieData.sceneVolume);
            setBackgroundVolume(existingCookieData.backGroundVolume);
            setUserCookies(data.game_data.user_id, {
              ...existingCookieData,
              theme: existingCookieData.theme,
              language: filLang,
            });
          } else {
            setTheme(prefersDarkMode);
            setLanguage(language);
            setUserCookies(data.game_data.user_id, {
              sound: true,
              sceneVolume: 1,
              theme: prefersDarkMode,
              language: language,
              backGroundVolume: 1,
            });
          }

          sessionStorage.setItem(
            SESSIONSTORAGE_USER_ID,
            data.game_data.user_id,
          );

          if (data.game_data.user_type === "S") {
            const baseChips = [...data.game_data.base_chips];
            const favoriteChips = [...data.game_data.favorite_chips];

            let newBaseChips: number[] = [];
            let newFavoriteChips: number[] = [];

            if (baseChips && baseChips.length > 0) {
              newBaseChips = baseChips;
            } else {
              newBaseChips = CHIP_VALUES.sort((a, b) => a - b);
            }

            if (favoriteChips && favoriteChips.length > 0) {
              const filteredFavoriteChips = newBaseChips
                .filter((chip) => favoriteChips.includes(chip))
                .sort((a, b) => a - b);
              newFavoriteChips = filteredFavoriteChips;
            } else {
              newFavoriteChips = newBaseChips;
            }
            baseChipsVar(newBaseChips);
            sessionStorage.setItem("base_chips", JSON.stringify(newBaseChips));
            defaultChips(newFavoriteChips);
            sessionStorage.setItem(
              SESSIONSTORAGE_DEFAULT_CHIPS,
              JSON.stringify(newFavoriteChips),
            );
          } else if (data.game_data.user_type === "P") {
            sessionStorage.setItem(SESSIONSTORAGE_ACTIVE_LAYOUT, "Games");
            sessionStorage.setItem(
              SESSIONSTORAGE_ACTIVE_CATEGORY,
              "phone_baccarat",
            );
            activeCatVar("phone_baccarat");
            // Phone User
            const defaultChipsArray = CHIP_VALUES.sort((a, b) => a - b);
            defaultChips(defaultChipsArray);
            sessionStorage.setItem(
              SESSIONSTORAGE_DEFAULT_CHIPS,
              JSON.stringify(defaultChipsArray),
            );
          }
          if (data.game_data.type === 1 || isTargetDomain) {
            isTypeHeritageVar(true);
            sessionStorage.setItem(SESSIONSTORAGE_ACTIVE_LAYOUT, "Games");
          }

          setIsSuccess(true);
          router.replace("/lobby");
          router.refresh;

          // console.log("Received Key");
          // if (socket) {
          //   socket.emit(
          //     "event:user:connect",
          //     {
          //       agent_id: data.game_data.agent_id,
          //       user_id: data.game_data.user_id,
          //       user_type: data.game_data.user_type,
          //       token: accessToken.replace("Bearer ", ""),
          //     },
          //     (data: GenerateKeyPayload) => {
          //       if (data.code === 0) {
          //         console.log("On Generate Key Login");
          //         userBalance(data.balance.toString());
          //         sessionStorage.setItem(
          //           SESSIONSTORAGE_BALANCE,
          //           data.balance.toString(),
          //         );
          //         currencyRate(Number(data.rate));
          //         sessionStorage.setItem("currency_rate", data.rate);
          //         setIsSuccess(true);
          //         router.replace("/lobby");
          //         console.log("Received Key");
          //       } else {
          //         alert("Internal server error. Please try again later.");
          //       }
          //     },
          //   );
          // }
        }
      }
    }
  };

  if (!isI18nReady) {
    return null; // or a loading spinner
  }

  return (
    <div
      className={`flex w-full flex-col items-center justify-center rounded-none md:rounded-[1.5rem] ${
        (width || 0) <= 430 && (width || 0) <= 932
          ? "h-full bg-black/50"
          : "h-full bg-black/50 md:min-w-[200px] md:max-w-[420px] md:bg-transparent"
      }`}
    >
      <div
        className={`flex items-center ${
          (height || 0) <= 430 && (width || 0) <= 932
            ? "w-[650px] flex-row gap-28 p-8"
            : "max-h-[calc(100%-1em)] w-full flex-col rounded-none bg-transparent p-8 md:rounded-[1.5rem] md:bg-black/90"
        }`}
      >
        <Image
          src={DWVIPlogo}
          alt="DW LOGO"
          className="max-w-[166px] self-center py-[20px]"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={`flex w-full flex-col ${
            (height || 0) <= 430 && (width || 0) <= 932 ? "gap-4" : "gap-8"
          }`}
        >
          <div
            className={`${
              (height || 0) <= 430 && (width || 0) <= 932
                ? "space-y-4"
                : "space-y-10"
            }`}
          >
            <div
              className={`flex border border-b border-l-0 border-r-0 border-t-0 border-solid border-gray-400 ${
                (height || 0) <= 430 && (width || 0) <= 932
                  ? "py-0"
                  : "py-0 md:py-2"
              }`}
            >
              <Image
                src={`data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAACMAAAApCAYAAAC/QpA/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ1SURBVHgB1ZhRbtpAEIZnx9DX+gZ139MIK1Fea04QeoLSE8ANUk7Q3ID0BukJ4vdIMWqaPnME+liFePMvOBYCzM7aYCe/BEbWsPvx73pmFkUllCRJ0CL6qoh6mijALR+vGV6xTtNfxycnV1RCihx1nyQXuHy3DDp9JOqGYTilQ8H8SZIxnOgLw2cYvHsUhhNhPLE08AGOOIAY+Yi/MUsq/YIIxgyYWpamCKhNdCENFsG0lRIPuC7jJn6ML4kVwWitO1RBXpr2JHHSPVMJJnv8rbLCSC3eh6wwyBUmmc2oBkmXSZwrtilljiVxIpgUKZ5KCvtlCndjSawMhvnKDEpllKYjaai4HKAmRbjckJviT2HYlQaLywEGjeHON5IrnhN9cYh3r9pZ+zDGx6ggxBTIEQrkJTnKGWYVipFZmTmAY+8x0D/cvoYbkywdvG1Vccan+bzjwZnFDa1ncGp6dHZWOic5wRgA7JcBvtTfUW+W7efT08/j09NrcpAIxkB46EsQPCQHmfYTCXMk7YmtMPe3t5FqtcbSylswySV64pFtY++EQaIb4OL8iBZMZG3SC5Pew/IUsBcQI+Ns29ITb3Vmn45smXCSObSxZBvOZOQHATGCQ52iJn0Dpu1eDMsADX8nSW8nTHY2CqgGYeIf6y1tDmOWx/GQVknmRyNjD7fCvMNBvi5X8smZB6vu5DB1urIiH+70aRUGm6lftys5APN5/tm8KaXOqTlFf+/uPuQwKP8RNahHrRftKZtCSMt/nhrTy1IxjiGVztF70oKB2fM+U/Pyzb5h7JdGl+hF/5X6yKqhR3pd+A8nYP1KYMDht1TZM/QB9Aw0rtwoGcZ9egAAAABJRU5ErkJggg==`}
                alt="DW LOGO"
                width={100}
                height={100}
                className="h-[18px] w-[16px] md:h-[24px] md:w-[20px]"
              />
              <input
                {...register("user_id")}
                type="text"
                name="user_id"
                placeholder={t("account")}
                required
                pattern="^[a-zA-Z0-9]*$"
                className="input block w-full border-none bg-transparent pl-3 text-white outline-none placeholder:text-gray-400 focus:placeholder:opacity-0"
                autoFocus
                disabled={isActiveApiGameUrl}
              />
            </div>
            <div
              className={`flex border border-b border-l-0 border-r-0 border-t-0 border-solid border-gray-400 ${
                (height || 0) <= 430 && (width || 0) <= 932
                  ? "py-0"
                  : "py-0 md:py-2"
              }`}
            >
              <Image
                className="h-[18px] w-[16px] md:h-[24px] md:w-[20px]"
                src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAqCAYAAADbCvnoAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJFSURBVHgB7ZjBUdwwFIZ/2QrXuANcQJhZHbg7FRAqwKkgkwqyW0FIBYEKQgfZGwdmkCfJ5BqnAswZW+ZpvQZh1hhJO+CDvxmvJVmSfz89SU/L4Ig8P4/DnZ2kBuKQ87e6rCrLa7oVSqlM7O8v4QCDJb+kTKnRESWTgaoF1Tu7ARZCiBzbFiSljN8AP8giM9gz3xNi8ZyKzxJEYmYc+EnJqKeTXN/r5nlfnZN3QnyEryBtGRIjOy8q6PpG17IEMhqSwqwf0nBuGlYqOyZRn+Ej6I+U/7TjGkVaxKEpoo+1v301P4bavqe2S7gIWnf43Sg6I184hAW/pUzQDHf7woysJPrqB3gCavypTZOVcvq6J829iT1tDaUWRj8z2Yi0E6R9AcaMoo5ObaavSRkEx2j8bkXY+JedoLDjkIpmCRzR/kaL5WmbJ8t/gK0gxtiBkc1crXPXXxBkRjb6e3m5CxtBqOvoPln/hycVzU4zXyolrAQxc6ozdoVtw1hkJei1mAQNMTpBXP/Ii4uk+6A29h9y8GhTHRtCpWIE999Py0D8qE/O89VeRvtNjRFAi+di8qEhJkFDjHPae6LjnKxuAv2EPQx3X1aQFlE1MXLeltESMqfbFzjiNWRdMRoKWefohBovJag3aNPhLhxxFsR6DoQrqmrwiNSHsyB9VlsfBB53yvkBHPHyIX3W74qig+URhbwpHPESpM9YgVJpp+wEHkwr9RCToCG897IwCHZ9w1sTb0E0q1LOeYotMfnQEON06vZf1BFQ3AJdcbpPN+t9xwAAAABJRU5ErkJggg==`}
                alt=""
                width={100}
                height={100}
              />

              <input
                {...register("password")}
                required
                type="password"
                id="password"
                name="password"
                placeholder={t("password")}
                pattern="^[a-zA-Z\\d`~!@#$%^&*()-_=+]*$"
                minLength={4}
                maxLength={20}
                className="input block w-full border-none bg-transparent pl-3 text-white outline-none placeholder:text-gray-400 focus:placeholder:opacity-0"
                disabled={isActiveApiGameUrl}
              />
            </div>
          </div>
          <div
            className={
              (height || 0) <= 430 && (width || 0) <= 932
                ? "space-y-[10px]"
                : "space-y-4"
            }
          >
            <div
              className={clsx(
                "relative w-full flex-grow cursor-pointer rounded-xl bg-[#D1B277]",
                {
                  "hover:bg-[#D1B277]/75": !isActiveApiGameUrl,
                },
              )}
            >
              <button
                disabled={isSubmitting || isSuccess || isActiveApiGameUrl}
                role="submit"
                className={`h-11 w-full whitespace-nowrap border-none px-6 font-medium text-white focus:outline-none md:h-14 ${isMobile ? "text-[24px]" : "text-3xl"}`}
              >
                {t("login")}
                {isSubmitting || isSuccess ? (
                  <div className="absolute left-0 top-0 z-10 grid h-full w-full place-content-center bg-black/30">
                    <Loader />
                  </div>
                ) : null}
              </button>
            </div>

            <div className="w-full flex-grow cursor-pointer rounded-xl border border-gray-300 bg-inherit">
              <button
                disabled={isActiveApiGameUrl}
                onClick={() => {
                  alert("Unavailable");
                }}
                className={`h-11 w-full whitespace-nowrap border-none px-6 text-center font-medium text-white focus:outline-none md:h-14 ${isMobile ? "text-[24px]" : "text-3xl"}`}
              >
                {t("visitor")}
              </button>
            </div>
          </div>

          <button
            type="button"
            disabled={isActiveApiGameUrl}
            className="flex justify-center"
            onClick={() => {
              setIsLangTriggered(true);
            }}
          >
            <LoginMultiLang />
          </button>
        </form>
      </div>
    </div>
  );
};

export default SubmitMessage;
