export const SystemErrorIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.1029 7.75C67.8349 4.75 72.1651 4.75 73.8971 7.75L125.859 97.75C127.591 100.75 125.426 104.5 121.962 104.5H18.0385C14.5744 104.5 12.4093 100.75 14.1414 97.75L66.1029 7.75Z"
        fill="#FFDA0A"
        stroke="#575757"
      />
      <path
        d="M69.4609 75.4453L64.6562 45.4453C64.5781 44.9245 64.513 44.4427 64.4609 44C64.4349 43.5573 64.4219 43.1667 64.4219 42.8281C64.4219 40.7969 64.9688 39.2083 66.0625 38.0625C67.1562 36.9167 68.6927 36.3438 70.6719 36.3438C72.651 36.3438 74.2005 36.9167 75.3203 38.0625C76.4401 39.2083 77 40.7969 77 42.8281C77 43.1667 76.974 43.5573 76.9219 44C76.8958 44.4427 76.8438 44.9245 76.7656 45.4453L71.8828 75.4453H69.4609ZM70.6719 90.4062C68.9792 90.4062 67.5339 89.8203 66.3359 88.6484C65.1641 87.4766 64.5781 86.0443 64.5781 84.3516C64.5781 82.6328 65.1641 81.1875 66.3359 80.0156C67.5339 78.8438 68.9792 78.2578 70.6719 78.2578C72.3385 78.2578 73.7708 78.8568 74.9688 80.0547C76.1667 81.2266 76.7656 82.6589 76.7656 84.3516C76.7656 86.0182 76.1667 87.4505 74.9688 88.6484C73.7708 89.8203 72.3385 90.4062 70.6719 90.4062Z"
        fill="black"
      />
    </svg>
  );
};

export const SystemMuted = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 55 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_932003_25848)">
        <rect x={20} y={20} width={15} height={14} fill="#828282" />
      </g>
      <path
        d="M33.7076 26.6264C33.7076 27.5544 33.4647 28.445 33.0226 29.2204L32.2098 28.4076C32.4714 27.8595 32.6115 27.2523 32.6115 26.6233C32.6115 24.9666 31.6337 23.4657 30.1141 22.7899C29.9148 22.7028 29.7871 22.5035 29.7871 22.2917C29.7871 22.1049 29.8774 21.9336 30.0362 21.8308C30.1919 21.7312 30.385 21.7125 30.5563 21.7903C32.4714 22.6374 33.7108 24.5338 33.7108 26.6233L33.7076 26.6264Z"
        fill="white"
      />
      <path
        d="M31.4905 26.6328C31.4905 26.9473 31.4344 27.2493 31.3285 27.5265L29.7871 25.9851V24.2163C30.7805 24.5682 31.4905 25.518 31.4905 26.6328Z"
        fill="white"
      />
      <path
        d="M28.4772 21.1522V24.6742L26.0576 22.2546L27.8824 20.8564C28.1253 20.6727 28.4772 20.8439 28.4772 21.1522Z"
        fill="white"
      />
      <path
        d="M22.5606 23.9697L28.4772 29.8894V32.1845C28.4772 32.4927 28.1253 32.6671 27.8824 32.4803L23.8155 29.3694H21.9035C21.4738 29.3694 21.125 29.0206 21.125 28.5909V24.7482C21.125 24.3185 21.4707 23.9697 21.9035 23.9697H22.5574H22.5606Z"
        fill="white"
      />
      <path
        d="M33.7072 33.1283C33.5484 33.1283 33.3927 33.0691 33.2713 32.9477L21.4972 21.1736C21.2574 20.9339 21.2574 20.5446 21.4972 20.3048C21.737 20.0651 22.1262 20.0651 22.366 20.3048L34.1401 32.0789C34.3798 32.3187 34.3798 32.7079 34.1401 32.9477C34.0186 33.0691 33.8629 33.1283 33.7041 33.1283H33.7072Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_932003_25848"
          x={0}
          y={0}
          width={55}
          height={54}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation={10}
            result="effect1_foregroundBlur_932003_25848"
          />
        </filter>
      </defs>
    </svg>
  );
};
