"use client";
import AuthLayout from "@/components/layouts/authLayout";
import SubmitMessage from "@/components/loginSubmit/login_submit";
import "../../../common/utils/i18n";
import { useUserAgent } from "@/common/hooks/useUserAgent";
import { useEventSourceContext } from "@/common/context/EventSourceContext";
import Maintenance from "@/components/maintenance/maintenance";
import LoggedOut from "@/components/logged-out/loggedOut";

const Login = () => {
  const { userAgent } = useUserAgent();
  const { activeMaintenance } = useEventSourceContext();
  const IS_LOGGED_OUT = process.env.NEXT_PUBLIC_IS_LOGGED_OUT;

  return (
    <div className="h-full w-full">
      {IS_LOGGED_OUT === "true" ? (
        <LoggedOut />
      ) : (
        <>
          {activeMaintenance ? (
            <Maintenance />
          ) : (
            <AuthLayout>
              <div className="text-x fixed inset-0 flex items-center justify-center">
                <SubmitMessage />
              </div>
            </AuthLayout>
          )}
        </>
      )}
    </div>
  );
};

export default Login;
