import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { IBetRecord } from "../hooks/useBetting";
import { useReactiveVar } from "@apollo/client";
import { selectedTablesVar } from "../utils/apollo";
import { SESSIONSTORAGE_LIST_BETS } from "../utils/constants";
import { getSessionStorageItem } from "../utils/sessionStorage";

type IListTotalBets = {
  tableId: string;
  gameCode: string;
  currentBet: IBetRecord;
  isValid: boolean;
};

type ListBetsContextType = {
  listTotalBets: IListTotalBets[];
  updateListBets: (
    tableId: string,
    gameCode: string,
    currentBet: IBetRecord,
    isValid?: boolean,
  ) => void;
  getTotalBetById: (tableId: string, gameCode: string) => IBetRecord;
  getSumTotalBetById: (tableId: string, gameCode: string) => number;
  getIsValidById: (tableId: string, gameCode: string) => boolean;
};

// Create Context
const ListBetsContext = createContext<ListBetsContextType | undefined>(
  undefined,
);

// Custom hook to use the context
export const useListBets = () => {
  const context = useContext(ListBetsContext);
  if (!context) {
    throw new Error("useListBets must be used within a ListBetsProvider");
  }
  return context;
};

// Provider component
export const ListBetsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const session = getSessionStorageItem(SESSIONSTORAGE_LIST_BETS);
  const sessionbets: IListTotalBets[] = JSON.parse(session || "[]");

  const activeTables = useReactiveVar(selectedTablesVar);
  const [listTotalBets, setListTotalBets] =
    useState<IListTotalBets[]>(sessionbets);

  // console.log("session bets", sessionbets);
  // console.log("LIST BETS", listTotalBets, Date.now());

  // Function to get the sum of currentBet based on tableId and gameCode
  const getSumTotalBetById = useCallback(
    (tableId: string, gameCode: string) => {
      const data = listTotalBets.find(
        (bet) => bet.tableId === tableId && bet.gameCode === gameCode,
      );

      const sum = Object.values(data?.currentBet || {}).reduce(
        (acc, val) => acc + val,
        0,
      );
      return sum || 0;
    },
    [listTotalBets],
  );

  // Function to get data based on tableId and gameCode
  const getTotalBetById = (tableId: string, gameCode: string) => {
    const data = listTotalBets.find(
      (bet) => bet.tableId === tableId, // && bet.gameCode === gameCode
    );
    return (
      data?.currentBet || {
        P: 0,
        B: 0,
        T: 0,
        PP: 0,
        BP: 0,
        S6: 0,
        FP: 0,
        EP: 0,
        L6: 0,
        PB: 0,
        BB: 0,
        BIG: 0,
        SMALL: 0,
      }
    );
  };

  // Function to get isValid status based on tableId and gameCode
  const getIsValidById = (tableId: string, gameCode: string) => {
    const data = listTotalBets.find(
      (bet) => bet.tableId === tableId && bet.gameCode === gameCode,
    );
    return data?.isValid || false;
  };

  // const updateListBets = useCallback(
  //   (
  //     tableId: string,
  //     gameCode: string,
  //     currentBet: IBetRecord,
  //     isValid = true,
  //   ) => {
  //     setListTotalBets((prevListBets) => {
  //       const existingBetIndex = prevListBets.findIndex(
  //         (bet) => bet.tableId === tableId,
  //       );

  //       if (existingBetIndex !== -1) {
  //         const existingBet = prevListBets[existingBetIndex];

  //         if (existingBet.gameCode === gameCode) {
  //           // Update isValid to false only if the props isValid is false
  //           if (!isValid) {
  //             const updatedBet = {
  //               ...existingBet,
  //               isValid: false,
  //             };
  //             const updatedListBets = [...prevListBets];
  //             updatedListBets[existingBetIndex] = updatedBet;
  //             return updatedListBets;
  //           }
  //           return prevListBets; // No changes needed
  //         }

  //         // If gameCode is not the same, update gameCode and set isValid to true
  //         const updatedBet = {
  //           ...existingBet,
  //           gameCode,
  //           currentBet,
  //           isValid: true,
  //         };
  //         const updatedListBets = [...prevListBets];
  //         updatedListBets[existingBetIndex] = updatedBet;
  //         return updatedListBets;
  //       }

  //       // Add a new bet if tableId doesn't exist
  //       return [
  //         ...prevListBets,
  //         {
  //           tableId,
  //           gameCode,
  //           currentBet,
  //           isValid: true,
  //         },
  //       ];
  //     });
  //   },
  //   [],
  // );

  const updateListBets = useCallback(
    (
      tableId: string,
      gameCode: string,
      currentBet: IBetRecord,
      isValid = true,
    ) => {
      setListTotalBets((prevListBets) => {
        const existingBetIndex = prevListBets.findIndex(
          (bet) => bet.tableId === tableId,
        );

        let updatedListBets = [...prevListBets];

        if (existingBetIndex !== -1) {
          const existingBet = prevListBets[existingBetIndex];

          const updatedBet = {
            ...existingBet,
            gameCode,
            currentBet,
            isValid: true,
          };
          updatedListBets[existingBetIndex] = updatedBet;

          // Validation to check if gameCode is the same
          // if (existingBet.gameCode === gameCode) {
          //   // Update isValid to false only if the props isValid is false
          //   if (!isValid) {
          //     const updatedBet = {
          //       ...existingBet,
          //       isValid: false,
          //     };
          //     updatedListBets[existingBetIndex] = updatedBet;
          //   }
          // } else {
          //   // If gameCode is not the same, update gameCode and set isValid to true
          //   const updatedBet = {
          //     ...existingBet,
          //     gameCode,
          //     currentBet,
          //     isValid: true,
          //   };
          //   updatedListBets[existingBetIndex] = updatedBet;
          // }
        } else {
          // Add a new bet if tableId doesn't exist
          updatedListBets = [
            ...prevListBets,
            {
              tableId,
              gameCode,
              currentBet,
              isValid: true,
            },
          ];
        }

        // Update session storage
        sessionStorage.setItem(
          SESSIONSTORAGE_LIST_BETS,
          JSON.stringify(updatedListBets),
        );
        return updatedListBets;
      });
    },
    [],
  );

  useEffect(() => {
    setListTotalBets((prevListBets) => {
      const updatedListBets = prevListBets.filter((bet) =>
        activeTables.some((table) => table.tableId === bet.tableId),
      );
      return updatedListBets;
    });
  }, [activeTables]);

  return (
    <ListBetsContext.Provider
      value={{
        listTotalBets,
        updateListBets,
        getTotalBetById,
        getSumTotalBetById,
        getIsValidById,
      }}
    >
      {children}
    </ListBetsContext.Provider>
  );
};
