import { useReactiveVar } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Manager } from "socket.io-client";
import {
  accessToken as accessTokenVar,
  currencyRate,
  failCountVar,
  hideAddTableVar,
  homeURLVar,
  isB2cActiveVar,
  isOnlineVar,
  latencyVar,
  userBalance,
  userDataVar,
} from "../utils/apollo";
import { SESSIONSTORAGE_BALANCE } from "../utils/constants";
import { GenerateKeyPayload } from "@/components/loginSubmit/login_submit";
import { useSystemAlert } from "./SystemAlertProvider";
import { ILogoutData } from "../api/listeners/table.listener";
import { getAllErrorMessage } from "../enum/allErrorMessages";
import { usePathname } from "next/navigation";

const manager = new Manager(process.env.NEXT_PUBLIC_API_URL!, {
  transports: ["websocket"],
  autoConnect: false,
});

type SocketManagerContext = {
  manager: Manager;
  connected: boolean;
};

const SocketManagerContext = createContext<SocketManagerContext | undefined>(
  undefined,
);

export function useSocketManager() {
  const context = useContext(SocketManagerContext);
  if (context === undefined) {
    throw new Error(
      "useSocketManager must be used within a SocketManagerProvider.",
    );
  }
  return context;
}

export function SocketManagerProvider({ children }: { children: ReactNode }) {
  const socket = manager.socket("/");
  const userData = useReactiveVar(userDataVar);
  const accessToken = useReactiveVar(accessTokenVar);
  const isB2cActive = useReactiveVar(isB2cActiveVar);
  const { showSysModal: showModal } = useSystemAlert();
  const [connected, setConnected] = useState(false);
  // const [connected, setConnected] = useState(socket.connected);
  const [sysType, setSysType] = useState(0);

  const onConnectHandler = useCallback(() => {
    console.log("Connected to main namespace");
    if (isB2cActive) {
      console.log("##cc: socket connection");
      setConnected(true);
    }
    if (userData && accessToken) {
      socket.emit(
        "event:user:connect",
        {
          agent_id: userData.agent_id,
          user_id: userData.user_id,
          user_type: userData.user_type,
          token: accessToken,
        },
        (data: GenerateKeyPayload) => {
          if (data.code === 0) {
            console.log("On Generate Key Login");
            userBalance(data.balance.toString());
            sessionStorage.setItem(
              SESSIONSTORAGE_BALANCE,
              data.balance.toString(),
            );
            currencyRate(Number(data.rate));
            sessionStorage.setItem("currency_rate", data.rate);
            setConnected(true);
          } else {
            alert("Internal server error. Please try again later.");
            setConnected(false);
          }
        },
      );
      console.log("User connect emitted");
    }
  }, [userData, accessToken, socket, isB2cActive]);

  const onDisconnectHandler = useCallback(() => {
    console.log("Disconnected to main namespace");
    setConnected(false);
  }, []);

  const sessionStatusHandler = useCallback(
    (data: ILogoutData) => {
      setSysType(data.type);
      const message = getAllErrorMessage(data.code, data.message);
      showModal({ message: message, alrtcode: data.code, alrttype: data.type });
    },
    [showModal],
  );

  useEffect(() => {
    // Prevent Reconnecting once rerouting is to login
    if (sysType !== 1) {
      socket.on("connect", onConnectHandler);
      socket.on("disconnect", onDisconnectHandler);
      socket.on("event:session:status", sessionStatusHandler);
    }
    return () => {
      socket.off("connect", onConnectHandler);
      socket.off("disconnect", onDisconnectHandler);
      socket.off("event:session:status", sessionStatusHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConnectHandler, onDisconnectHandler, sessionStatusHandler, socket]);

  const [isOffline, setIsOffline] = useState(false);
  const [offlineTime, setOfflineTime] = useState(0);
  const homeUrl = useReactiveVar(homeURLVar);
  const failCount = useReactiveVar(failCountVar);
  const hideAddTable = useReactiveVar(hideAddTableVar);

  const handleOnline = useCallback(() => {
    // isOnlineVar(true);
    setIsOffline(false);
    setOfflineTime(0);
    socket.connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOffline = useCallback(() => {
    isOnlineVar(false);
    setIsOffline(true);
    setOfflineTime(Date.now());
    socket.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [handleOffline, handleOnline]);

  useEffect(() => {
    if (isOffline && offlineTime) {
      const timeoutId = setTimeout(() => {
        if (Date.now() - offlineTime >= 30000) {
          console.log("Offline more than 30 seconds. Redirecting...");
          // if (homeUrl && homeUrl !== "") {
          //   sessionStorage.clear();
          //   window.location.assign(homeUrl);
          // } else {
          //   sessionStorage.clear();
          //   window.location.assign("/login");
          // }
          sessionStorage.clear();
          if (userData.user_type === "A" && hideAddTable === 1) {
            if (homeUrl && homeUrl !== "") {
              window.location.assign(homeUrl);
            } else {
              window.location.assign("/login");
            }
          } else if (userData.user_type === "A" && hideAddTable === 0) {
            window.location.assign("/login");
          } else {
            window.location.assign("/login");
          }
        }
      }, 30000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOffline, offlineTime]);

  useEffect(() => {
    if (!isB2cActive) {
      if (failCount > 3) {
        // if (homeUrl && homeUrl !== "") {
        //   sessionStorage.clear();
        //   window.location.assign(homeUrl);
        // } else {
        //   sessionStorage.clear();
        //   window.location.assign("/login");
        // }
        sessionStorage.clear();
        if (userData.user_type === "A" && hideAddTable === 1) {
          if (homeUrl && homeUrl !== "") {
            window.location.assign(homeUrl);
          } else {
            window.location.assign("/login");
          }
        } else if (userData.user_type === "A" && hideAddTable === 0) {
          window.location.assign("/login");
        } else {
          window.location.assign("/login");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failCount]);

  useEffect(() => {
    const emitEvent = () => {
      const { agent_id, user_id } = userData;
      const payload = {
        agent_id: agent_id,
        user_id: user_id,
        time_stamp: new Date().getTime(),
      };
      if (connected) {
        const startTime = performance.now();
        socket
          .timeout(3000)
          .emit("ping", payload, (error: any, response: number | undefined) => {
            if (response) {
              console.log("Latency ", response);
              failCountVar(0);
              const endTime = performance.now();
              const roundTripTime = endTime - startTime;
              console.log(`Round trip time: ${roundTripTime.toFixed(2)}ms`);
              latencyVar(roundTripTime);
            }
            if (error) {
              failCountVar(failCountVar() + 1);
            }
          });
      }
    };
    const intervalId = setInterval(emitEvent, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [socket, userData, connected]);

  return (
    <SocketManagerContext.Provider value={{ manager, connected }}>
      {children}
    </SocketManagerContext.Provider>
  );
}
