"use client";

import { useCookieContext } from "@/common/context/CookieProvider";
import { useVolumeContext } from "@/common/context/VolumeProvider";
import { useUserAgent } from "@/common/hooks/useUserAgent";
import { useReactiveVar } from "@apollo/client";

import React, {
  AudioHTMLAttributes,
  RefObject,
  useEffect,
  useRef,
} from "react";

interface ISoundProps extends AudioHTMLAttributes<HTMLAudioElement> {
  sref: RefObject<HTMLAudioElement>;
  src: string;
  volume?: number; // Optional volume prop
  scene?: boolean;
}

const Sound: React.FC<ISoundProps> = ({ sref, scene, src, ...rest }) => {
  const { backgroundVolume } = useCookieContext();

  useEffect(() => {
    if (sref?.current) {
      sref.current.volume = backgroundVolume;
    }
  }, [backgroundVolume, sref]);

  return <audio ref={sref} src={src} {...rest} />;
};

export default Sound;
