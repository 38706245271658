import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import {
  isApiGameUrlActiveVar,
  isB2cActiveVar,
  isTypeHeritageVar,
} from "../utils/apollo";
import { useReactiveVar } from "@apollo/client";
import { useRouter } from "next/navigation";

interface DomainContextType {
  isTargetDomain: boolean | null;
}

const DomainContext = createContext<DomainContextType | undefined>(undefined);

interface DomainProviderProps {
  children: ReactNode;
}

export const DomainProvider: React.FC<DomainProviderProps> = ({ children }) => {
  const router = useRouter();

  const targetDomain = process.env.NEXT_PUBLIC_HERITAGE_URL;
  const targetB2cDomain = process.env.NEXT_PUBLIC_B2C;
  const targetGeoblock = process.env.NEXT_PUBLIC_API_GAME_URL;
  const isB2c = process.env.NEXT_PUBLIC_IS_B2C;
  const isTypeHeritage = useReactiveVar(isTypeHeritageVar);
  const [isTargetDomain, setIsTargetDomain] = useState<boolean | null>(null);

  useEffect(() => {
    const currentDomain = window.location.origin;
    const gameUrlLogin = window.location.href;

    // BLOCKING LOGIN FOR NEXT_PUBLIC_API_GAME_URL
    if (targetGeoblock === currentDomain && gameUrlLogin.includes("/login")) {
      isApiGameUrlActiveVar(true);
    }

    // FOR HERITAGE
    if (isTypeHeritage) {
      setIsTargetDomain(true);
    } else {
      setIsTargetDomain(currentDomain === targetDomain);
    }

    // FOR B2C
    isB2cActiveVar(currentDomain === targetB2cDomain && isB2c === "true");

    // FOR GEOBLOCK
    // if (targetGeoblock === currentDomain) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   router.push("/geoblock");
    // }
  }, [
    isTypeHeritage,
    targetDomain,
    targetB2cDomain,
    isB2c,
    router,
    targetGeoblock,
  ]);

  return (
    <DomainContext.Provider value={{ isTargetDomain }}>
      {children}
    </DomainContext.Provider>
  );
};

export const useDomainCheck = (): boolean | null => {
  const context = useContext(DomainContext);
  if (!context) {
    throw new Error("useDomainCheck must be used within a DomainProvider");
  }
  return context.isTargetDomain;
};
