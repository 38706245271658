"use client";
import React from "react";
import Image from "next/image";
import maintanceLogo from "@/assets/maintenance/tool.png";
import ellipseBottom from "@/assets/maintenance/ellipse-bottom.png";
import ellipseTop from "@/assets/maintenance/ellipse-top.png";
import dowinnlogo from "@/assets/maintenance/dowinn-logo.png";
import { t } from "i18next";
import { useCookieContext } from "@/common/context/CookieProvider";

type SupportedLanguages = "kr" | "cn" | "jp" | "en";

const Maintenance = () => {
  const { language } = useCookieContext() as { language: SupportedLanguages };

  const maintenanceMessage = [
    {
      kr: "점검중",
      cn: "维护中",
      jp: "メンテナンス中",
      en: "Under Maintenance",
    },
    {
      kr: "저희 웹사이트는 현재 유지보수 중입니다. 곧 다시 돌아오겠습니다. 양해해 주셔서 감사합니다!",
      cn: "我们的网站正在维护中。我们很快就会恢复。感谢您的耐心等待！",
      jp: "現在、当ウェブサイトはメンテナンス中でございます。再開まで今しばらくお待ちいただきますようお願い申し上げます。ご理解のほど、誠にありがとうございます。",
      en: "Our website is temporarily down for maintenance. We’ll be back shortly. Thanks for your patience!",
    },
  ];

  const titleToDisplay =
    maintenanceMessage[0][language] || maintenanceMessage[0].en;
  const textToDisplay =
    maintenanceMessage[1][language] || maintenanceMessage[1].en;

  return (
    <div className="flex h-screen items-center justify-between px-24 min-[300px]:max-[900px]:justify-center min-[300px]:max-[900px]:text-center min-[300px]:max-[600px]:mt-[-10%]">
      <div className="absolute left-0 min-[300px]:max-[900px]:hidden">
        <Image src={ellipseTop} alt="" className="h-full w-[30cqw]" />
      </div>
      <div className="z-20 mt-[-5%] flex w-[60%] flex-col min-[300px]:max-[900px]:w-[100%] min-[300px]:max-[900px]:items-center min-[300px]:max-[900px]:justify-center">
        <Image
          src={maintanceLogo}
          alt=""
          className="w-[120px] min-[300px]:max-[900px]:w-[80px]"
        />
        <h1 className="my-8 text-nowrap text-[54px] font-bold text-white min-[300px]:max-[900px]:my-2 min-[300px]:max-[900px]:text-[24px]">
          {titleToDisplay}
        </h1>
        <p className="w-[55%] text-[18px] text-[#A1A1AA] min-[300px]:max-[900px]:w-[80%] min-[300px]:max-[900px]:text-[14px]">
          {textToDisplay}
        </p>
      </div>

      <div className="flex w-[40%] justify-center opacity-80 min-[300px]:max-[900px]:absolute min-[300px]:max-[600px]:w-full">
        <Image
          src={dowinnlogo}
          alt=""
          className="z-20 h-full w-[30cqw] min-[300px]:max-[900px]:w-full"
        />
        <div className="absolute bottom-0 right-0 min-[300px]:max-[900px]:hidden">
          <Image src={ellipseBottom} alt="" className="h-full w-[60cqw]" />
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
