import { AxiosError, AxiosResponse } from "axios";
import { apiClient } from "../apiConfig";
import { Limit } from "@/common/hooks/game-data/types";
import { accessToken as accessTokenVar } from "@/common/utils/apollo";

export interface ILoginReq {
  user_id: string;
  password: string;
  language: string;
}

export type LoginResponse = {
  code: number;
  game_data: {
    agent_id: string;
    user_id: string;
    currency: string;
    language: string;
    favorite_chips: number[];
    base_chips: number[];
    display_name: string;
    auto_confirm: boolean;
    video_zoom: boolean;
    betting_board: boolean;
    user_type: string;
    member_limit: Limit;
    ratio: number;
    view: number;
    type?: number;
    user_type2: string;
  };
};

export const LOGIN_MUTATION = async (payload: ILoginReq) => {
  try {
    const response = await apiClient.post("/login", payload);
    const mutationResponse: AxiosResponse = response;
    return mutationResponse;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error("Axios error:", error.message);
      return error;
    } else {
      console.error("Unexpected error:", error);
      return undefined;
    }
  }
};
