import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useRouter } from "next/navigation";
import SystemModal from "@/components/common/system_modal";
import { useReactiveVar } from "@apollo/client";
import {
  hideAddTableVar,
  homeURLVar,
  selectedTablesVar,
  userDataVar,
} from "../utils/apollo";
import { useSocketManager } from "./SocketManagerContext";
import { useEventDispatcher } from "../hooks/useEventDispatcher";
import { SESSIONSTORAGE_USER_ID } from "../utils/constants";
import { getSessionStorageItem } from "../utils/sessionStorage";

export enum AlertType {
  LOGOUT = 1,
  LOBBY = 2,
  RESERVE1 = 3,
  RESERVE2 = 4,
  IDLE = 5,
  ERRBET = 6,
  FIVEROUND = 7,
  API_WITHOUT_LOBBY = 8,
  MSG_ONLY = 9,
  REFRESH = 10,
}

interface ISystemAlertContext {
  isSystemOpen: boolean;

  showSysModal: (data: {
    message: string;
    alrtcode: number;
    tableId?: string;
    alrttype?: AlertType;
  }) => void;
  hideSysModal: () => void;
  onCloseModal: () => void;
  toCloseTable: string[];
  show5BetroundModal: (table: string) => void;
  hide5roundBetModal: (table: string) => void;
}

const SystemAlertContext = createContext<ISystemAlertContext | undefined>(
  undefined,
);

export function SystemAlertProvider({ children }: { children: ReactNode }) {
  const name = getSessionStorageItem(SESSIONSTORAGE_USER_ID);
  const [isSystemOpen, setIsSystemOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [alrtCode, setAlrtCode] = useState(0);
  const [alrtType, setAlrtType] = useState<AlertType | undefined>(undefined);
  const [tableID, setTableId] = useState("");
  const [toCloseTable, setTocloseTable] = useState<string[]>([]);
  const router = useRouter();
  //const { dispatchEvent } = useEventDispatcher();
  const userData = useReactiveVar(userDataVar);
  const homeURL = useReactiveVar(homeURLVar);
  const hideAddTable = useReactiveVar(hideAddTableVar);
  const { dispatchEvent } = useEventDispatcher();

  // const showSysModal = (
  //   message: string,
  //   alrtcode: number,
  //   tableId?: string,
  //   alrttype?: AlertType,
  // )
  const showSysModal: (data: {
    message: string;
    alrtcode: number;
    tableId?: string;
    alrttype?: AlertType;
  }) => void = ({ message, alrtcode, tableId, alrttype }) => {
    setMessage(message);
    setAlrtCode(alrtcode);
    setAlrtType(alrttype);

    setIsSystemOpen(true);
    if (tableId) {
      setTableId(tableId);
    }
  };

  const hideSysModal = () => {
    setIsSystemOpen(false);
  };

  const show5BetroundModal = (tableToClose: string) => {
    setTocloseTable((prev) => [...prev, tableToClose]);
  };

  const hide5roundBetModal = (tableToClose: string) => {
    setTocloseTable((prev) => prev.filter((table) => table !== tableToClose));
  };

  const onCloseModal = () => {
    if (alrtType) {
      if (alrtType === AlertType.LOGOUT) {
        if (userData && userData.user_type) {
          // if (userData.user_type === "A") {
          //   sessionStorage.clear();
          //   window.location.assign(homeURL);
          //   router.push(homeURL);
          //   router.refresh();
          // } else {
          //   sessionStorage.clear();
          //   window.location.assign("/login");
          //   router.push("/login");
          //   router.refresh();
          // }
          sessionStorage.clear();
          if (userData.user_type === "A" && hideAddTable === 1) {
            if (homeURL && homeURL !== "") {
              window.location.assign(homeURL);
            } else {
              window.location.assign("/login");
            }
          } else if (userData.user_type === "A" && hideAddTable === 0) {
            window.location.assign("/login");
          } else {
            sessionStorage.clear();
            window.location.assign("/login");
          }
        }
      } else if (alrtType === AlertType.LOBBY) {
        // router.push("/lobby");
        // router.refresh();
        window.location.assign("/lobby");
      } else if (alrtType === AlertType.IDLE) {
        dispatchEvent("userMovement", {
          detail: {
            location: "lobby",
            component: "modal",
          },
        });
        router.refresh();
      } else if (alrtType === AlertType.FIVEROUND) {
        router.replace("/lobby");
      } else if (alrtType === AlertType.API_WITHOUT_LOBBY) {
        // sessionStorage.clear();
        // window.location.assign(homeURL);
        // router.push(homeURL);
        // router.refresh();
        sessionStorage.clear();
        if (homeURL && homeURL !== "") {
          window.location.assign(homeURL);
        } else {
          window.location.assign("/login");
        }
      } else if (alrtType === AlertType.REFRESH) {
        router.refresh();
      }
    }
    hideSysModal();
  };

  return (
    <SystemAlertContext.Provider
      value={{
        isSystemOpen,
        showSysModal,
        hideSysModal,
        toCloseTable,
        onCloseModal,
        show5BetroundModal,
        hide5roundBetModal,
      }}
    >
      {children}
      {isSystemOpen && name !== "" && (
        <SystemModal
          code={alrtCode}
          message={message}
          alrtType={alrtType}
          tableId={tableID}
          onCloseModal={onCloseModal}
        />
      )}
    </SystemAlertContext.Provider>
  );
}

export function useSystemAlert() {
  const context = useContext(SystemAlertContext);
  if (!context) {
    throw new Error("useSystemAlert must be used within a SystemAlertProvider");
  }
  return context;
}
