import axios, { AxiosInstance } from "axios";

const apiUrl = process.env.NEXT_PUBLIC_API_URL!;

export const apiClient: AxiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  xsrfCookieName: "Cookie",
});

apiClient.defaults.headers.common["X-CSRF-TOKEN"];
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 403
      // || error.response.data.message === "Unauthorized" // remove for now
    ) {
      sessionStorage.clear();
      sessionStorage.setItem("error", "403");
      window.location.href = "/403";
    } else if (
      error.response.status === 408 ||
      error.response.data.message === "Forbidden"
    ) {
      sessionStorage.clear();
      sessionStorage.setItem("error", "408");
      window.location.href = "/408";
    } else if (error.response.status === 500) {
      sessionStorage.clear();
      sessionStorage.setItem("error", "500");
      window.location.href = "/500";
    }
    return error;
  },
);

apiClient.defaults.withCredentials = true;
apiClient.defaults.xsrfCookieName = "Cookie";
apiClient.defaults.headers.common["Cookie"];
